import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaFilePdf, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import Swal from "sweetalert2";
import { del, getWithParams, patch } from "../../../utils/fetchAPI";
import CreateClubDeclarationModal from "./CreateClubDeclarationModal";
import EditClubDeclarationModal from "./EditClubDeclarationModal";

const ClubDeclarationsTable = ({
  clubDeclarations: initialClubDeclarations,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  setTotalRows,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedClubDeclaration, setSelectedClubDeclaration] = useState(null);
  const [clubDeclarations, setClubDeclarations] = useState(
    initialClubDeclarations
  );

  const baseUrl =
    "https://shukhee-public-bucket-dev.s3.ap-southeast-1.amazonaws.com";

  const handlePdfUpdate = async (clubDeclarationId, isFilePresent) => {
    const { value: file } = await Swal.fire({
      title: isFilePresent ? "Update PDF" : "Upload PDF",
      input: "file",
      inputAttributes: {
        accept: ".pdf",
      },
      showCancelButton: true,
      confirmButtonText: isFilePresent ? "Update" : "Upload",
      cancelButtonText: "Cancel",
    });

    if (file) {
      const formData = new FormData();
      formData.append("file", file);

      try {
        await patch(
          `admin/club-declarations/file/${clubDeclarationId}`,
          formData
        );

        Swal.fire(
          "Success!",
          isFilePresent
            ? "PDF file updated successfully."
            : "PDF file uploaded successfully.",
          "success"
        );

        setClubDeclarations((prevDeclarations) =>
          prevDeclarations.map((declaration) =>
            declaration._id === clubDeclarationId
              ? { ...declaration, file: file.name }
              : declaration
          )
        );

        const response = await getWithParams("admin/club-declarations", {
          page,
          limit,
        });

        if (response?.status === 200) {
          setClubDeclarations(response?.data?.data?.docs || []);
          setTotalRows(response?.data?.data?.totalDocs);
        } else {
          setClubDeclarations([]);
        }
      } catch (error) {
        console.error("Error uploading/updating PDF file:", error);
        Swal.fire("Error!", "Failed to upload/update the PDF file.", "error");
      }
    }
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "80px",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "300px",
    },
    {
      name: "Description",
      selector: (row) => row.description || "N/A",
      sortable: false,
      wrap: true,
    },
    {
      name: "Created At",
      selector: (row) => new Date(row.createdAt).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "Updated At",
      selector: (row) => new Date(row.updatedAt).toLocaleString(),
      sortable: true,
      wrap: true,
    },
    {
      name: "File",
      cell: (row) => (
        <div className="flex items-center space-x-2">
          {row.file ? (
            <>
              <FaFilePdf
                className="text-2xl text-red-500 cursor-pointer"
                onClick={() =>
                  window.open(
                    `${baseUrl}/${row.file}`,
                    "_blank",
                    "noopener,noreferrer"
                  )
                }
              />
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handlePdfUpdate(row._id, true)}
              >
                Update PDF
              </button>
            </>
          ) : (
            <>
              <span className="text-gray-500">N/A</span>
              <button
                className="text-blue-500 hover:underline"
                onClick={() => handlePdfUpdate(row._id, false)}
              >
                Upload PDF
              </button>
            </>
          )}
        </div>
      ),
      sortable: false,
      wrap: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => {
              setSelectedClubDeclaration(row);
              setIsEditModalOpen(true);
            }}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteClubDeclaration(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const handleDeleteClubDeclaration = (clubDeclarationId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        del(`admin/club-declarations/${clubDeclarationId}`)
          .then(() => {
            setClubDeclarations((prevClubDeclarations) =>
              prevClubDeclarations.filter(
                (clubDeclaration) => clubDeclaration._id !== clubDeclarationId
              )
            );
            Swal.fire(
              "Deleted!",
              "Club declaration deleted successfully.",
              "success"
            );
          })
          .catch((error) => {
            console.error("Error deleting club declaration", error);
            Swal.fire("Error!", "Failed to delete club declaration.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={clubDeclarations}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        highlightOnHover
        responsive
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Club Declarations</h1>
            <button
              onClick={() => setIsCreateModalOpen(true)}
              className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
              aria-label="Create club declaration"
            >
              <FaPlus className="mr-2" /> Create Club Declaration
            </button>
          </div>
        }
      />
      <EditClubDeclarationModal
        clubDeclaration={selectedClubDeclaration}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        onClubDeclarationUpdate={(updatedClubDeclaration) => {
          setClubDeclarations((prev) =>
            prev.map((declaration) =>
              declaration._id === updatedClubDeclaration._id
                ? { ...declaration, ...updatedClubDeclaration }
                : declaration
            )
          );
        }}
      />
      <CreateClubDeclarationModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onClubDeclarationCreate={(newClubDeclaration) =>
          setClubDeclarations((prev) => [newClubDeclaration, ...prev])
        }
      />
    </div>
  );
};

export default ClubDeclarationsTable;
