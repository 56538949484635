import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Select from "react-select";
import { toast } from "react-toastify";
import phoneCountryCodes from "../../components/phone-country-code/phone-country-code.json";
import { get } from "../../utils/fetchAPI";

const baseUrl =
  "https://shukhee-public-bucket-dev.s3.ap-southeast-1.amazonaws.com";

const getImageUrl = (url, defaultImage) => {
  return url ? `${baseUrl}/${url}` : defaultImage;
};

const ViewAdminModal = ({ isOpen, onClose, adminId }) => {
  const [adminDetails, setAdminDetails] = useState(null);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    const fetchAdminDetails = async () => {
      try {
        const response = await get(`/admin/admins/${adminId}`);
        if (response && response.status === 200 && response.data.success) {
          setAdminDetails(response.data.data);
        } else {
          throw new Error(response.data.message || "Failed to retrieve data");
        }
      } catch (error) {
        console.error("Error fetching admin details:", error);
        toast.error(error.message || "Failed to retrieve admin details.");
      }
    };

    if (isOpen && adminId) {
      fetchAdminDetails();
    }
  }, [isOpen, adminId]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await get("/admin/role/get-roles");

        // Log the response to verify structure
        console.log("Roles API Response:", response);

        // Access the nested data structure
        if (
          response.data.success &&
          response.data.statusCode === 200 &&
          response.data.data
        ) {
          const roleOptions = response.data.data.map((role) => ({
            value: role._id,
            label: role.name,
          }));
          setRoles(roleOptions);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error(
          "An error occurred while fetching roles. Please try again later."
        );
      }
    };

    fetchRoles();
  }, []);

  const getValue = (value) =>
    value !== undefined && value !== null && value !== "" ? value : "N/A";

  if (!adminDetails) {
    return null;
  }

  const phoneCodeOptions = phoneCountryCodes.map((country) => ({
    value: country.value,
    label: country.label,
  }));

  // Extracting the selected country code
  const selectedCountryCode =
    phoneCodeOptions.find(
      (option) => option.value === adminDetails.phone?.countryCode
    ) || null;

  // Get selected role
  const selectedRole =
    roles.find((role) => role.value === adminDetails.role?._id) || null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="fixed inset-0 flex items-center justify-center m-2"
      overlayClassName="fixed inset-0 bg-black bg-opacity-60 backdrop-blur-sm"
    >
      <div className="relative w-full max-w-[720px] max-h-[720px] overflow-auto rounded-lg bg-white shadow-lg p-4">
        <div className="flex items-start justify-between">
          <h2 className="text-xl font-bold mb-2">Admin Details</h2>
          <button
            onClick={onClose}
            className="relative h-8 w-8 rounded-lg text-primary transition-all hover:bg-primary/10"
            type="button"
          >
            <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
                className="h-5 w-5"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </span>
          </button>
        </div>

        {/* Profile Photo Section */}
        {/* <div className="flex flex-col items-center mb-4 relative">
          {adminDetails.profilePhoto ? (
            <img
              src={getImageUrl(
                adminDetails.profilePhoto,
                "/default-profile.jpg"
              )}
              alt="Profile"
              className="w-36 h-36 object-cover rounded-full"
            />
          ) : (
            <Avatar
              name={`${adminDetails.firstName} ${adminDetails.lastName}`}
              size="144"
              round={true}
            />
          )}
        </div> */}

        <div className="grid grid-cols-2 gap-4">
          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              First Name:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.firstName)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Last Name:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.lastName)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Email:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.email)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Username:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.username)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          {/* Phone Section with Two Columns */}
          {/* <div className="mb-1 col-span-2">
            <label className="block text-sm font-medium text-primary mb-1">
              Phone:
            </label>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <Select
                  value={selectedCountryCode}
                  options={phoneCodeOptions}
                  isDisabled
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      border: "1px solid #d1d5db",
                      borderRadius: "0.375rem",
                      height: "1.75rem",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#6b7280",
                      },
                    }),
                    input: (provided) => ({
                      ...provided,
                      fontSize: "0.875rem",
                      padding: "0.25rem",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      fontSize: "0.875rem",
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <div>
                <input
                  type="text"
                  value={getValue(adminDetails.phone?.number)}
                  disabled
                  className="border rounded-md p-1 w-full text-sm bg-gray-100"
                  style={{ height: "2.4rem" }}
                />
              </div>
            </div>
          </div> */}

          {/* Phone Section in a Single Field */}
          <div className="mb-2">
            <label className="block text-sm font-medium text-primary mb-1">
              Phone:
            </label>
            <div className="flex">
              <div className="flex items-center border rounded-l-md p-1 bg-gray-50">
                <Select
                  value={selectedCountryCode}
                  options={phoneCodeOptions}
                  isDisabled
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      minHeight: "1.75rem",
                      fontSize: "0.875rem",
                      border: "none",
                      boxShadow: "none",
                      minWidth: "110px",
                    }),
                    dropdownIndicator: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    valueContainer: (provided) => ({
                      ...provided,
                      padding: "0 4px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "200px",
                    }),
                  }}
                  className="w-full"
                />
              </div>
              <input
                type="text"
                name="phone.number"
                value={getValue(adminDetails.phone?.number)}
                disabled
                className={`border rounded-r-md p-1 w-full text-sm  bg-gray-100`}
                style={{ fontSize: "0.875rem", height: "2.4rem" }}
              />
            </div>
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Department:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.department)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Designation:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.designation)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div>

          <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Role:
            </label>
            <Select
              value={selectedRole}
              options={roles}
              isDisabled
              className="mt-1"
            />
          </div>

          {/* <div className="mb-2">
            <label className="block text-sm font-medium text-primary">
              Status:
            </label>
            <input
              type="text"
              value={getValue(adminDetails.status)}
              disabled
              className="mt-1 border rounded-md p-2 w-full text-sm bg-gray-100"
            />
          </div> */}
        </div>

        {/* Permissions Section */}
        <div className="mt-4">
          <h3 className="text-sm font-bold text-primary mb-2">Permissions</h3>
          {adminDetails.role?.permissions?.length > 0 ? (
            <div className="grid grid-cols-2 gap-4 text-xs">
              {adminDetails.role.permissions.map((permission, index) => (
                <div key={index} className="text-gray-700">
                  • {permission.slug}
                </div>
              ))}
            </div>
          ) : (
            <div className="text-xs text-gray-600">
              No permissions available for this role.
            </div>
          )}
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-semibold text-white bg-primary rounded-md hover:bg-primary/80"
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewAdminModal;
