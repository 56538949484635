import { DateTime } from "luxon";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FaEye, FaPencilAlt, FaPlus, FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { del } from "../../utils/fetchAPI";
import EditEventModal from "./ViewEventModal";

const EventTable = ({
  events: initialEvents,
  totalRows,
  page,
  limit,
  setPage,
  setLimit,
  search,
  setSearch,
  handleSearch,
  status,
  setStatus,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [events, setEvents] = useState(initialEvents);
  const [newStatusOptions, setNewStatusOptions] = useState([]);
  const [newStatus, setNewStatus] = useState("");

  const navigate = useNavigate();

  const baseUrl =
    "https://shukhee-public-bucket-dev.s3.ap-southeast-1.amazonaws.com";

  const getImageUrl = (url, defaultImage) => {
    return url ? `${baseUrl}/${url}` : defaultImage;
  };

  const formatDateTime = (dateString, timezone) => {
    const dt = DateTime.fromISO(dateString, { zone: "utc" }).setZone(timezone);

    const datePart = dt.toFormat("dd/MM/yyyy");
    const timePart = dt.toFormat("hh:mm a");

    return `${datePart} (${timePart})`;
  };
  const formatTimezone = (timezone) => {
    const dt = DateTime.now().setZone(timezone);
    const offset = dt.offset / 60;
    const formattedOffset = `UTC${offset >= 0 ? "+" : ""}${offset
      .toString()
      .padStart(2, "0")}:00`;

    return `${formattedOffset} ${dt.zoneName}`;
  };

  const columns = [
    {
      name: "SL",
      cell: (row, index) => (page - 1) * limit + index + 1,
      sortable: false,
      width: "50px",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Start Date (Time)",
      selector: (row) => formatDateTime(row.startDateTime, row.timezone),
      sortable: true,
      wrap: true,
      width: "180px",
    },
    {
      name: "End Date (Time)",
      selector: (row) => formatDateTime(row.endDateTime, row.timezone),
      sortable: true,
      wrap: true,
      width: "180px",
    },
    {
      name: "Timezone",
      selector: (row) => formatTimezone(row.timezone),
      sortable: true,
      wrap: true,
    },
    {
      name: "Place",
      selector: (row) => row.place,
      sortable: true,
      wrap: true,
      width: "250px",
    },
    {
      name: "Thumbnail Image",
      cell: (row) => (
        <img
          src={getImageUrl(row.thumbnail, "/default-thumbnail.jpg")}
          alt="Thumbnail"
          className="w-24 h-16 object-cover shadow-lg border"
        />
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <div className="flex justify-center">
          <h2
            className={`min-w-fit w-fit px-3 py-1 text-sm rounded-md capitalize text-center ${
              row.status ? "bg-green-200" : "bg-red-200"
            }`}
          >
            {row.status ? "Active" : "Inactive"}
          </h2>
        </div>
      ),
    },

    {
      name: "Actions",
      cell: (row) => (
        <div className="flex space-x-4">
          <button
            onClick={() => {
              setSelectedEvent(row);
              setIsModalOpen(true);
            }}
            className="text-blue-500 hover:text-blue-700"
          >
            <FaEye className="text-xl" />
          </button>
          <button
            onClick={() => navigate(`/events/edit/${row._id}`)}
            className="text-yellow-500 hover:text-yellow-700"
          >
            <FaPencilAlt className="text-xl" />
          </button>
          <button
            onClick={() => handleDeleteEvent(row._id)}
            className="text-red-500 hover:text-red-700"
          >
            <FaTrash className="text-xl" />
          </button>
        </div>
      ),
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#f9fafb",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newLimit) => {
    setLimit(newLimit);
    setPage(1);
  };

  const handleEventUpdate = (eventId, updatedEvent) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event._id === eventId ? { ...event, ...updatedEvent } : event
      )
    );
  };

  const handleDeleteEvent = (eventId, gallery = []) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const validGalleryImages =
          Array.isArray(gallery) && gallery.length > 0
            ? gallery.filter((imagePath) => imagePath)
            : [];

        const deleteGalleryImages = validGalleryImages.length
          ? Promise.all(
              validGalleryImages.map((imagePath) => {
                return del(
                  `/admin/events/gallery/${eventId}?path=${imagePath}`
                );
              })
            )
          : Promise.resolve();

        deleteGalleryImages
          .then(() => del(`admin/events/${eventId}`))
          .then(() => {
            setEvents((prevEvents) =>
              prevEvents.filter((event) => event._id !== eventId)
            );
            Swal.fire("Deleted!", "Event deleted successfully.", "success");
          })
          .catch((error) => {
            console.error("Error deleting event", error);
            Swal.fire("Error!", "Failed to delete event.", "error");
          });
      }
    });
  };

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg w-full">
      <DataTable
        columns={columns}
        data={events}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        paginationRowsPerPageOptions={[5, 10, 20, 50]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        highlightOnHover
        responsive
        customStyles={customStyles}
        subHeader
        subHeaderComponent={
          <div className="flex justify-between items-center w-full">
            <h1 className="text-xl font-bold">Events</h1>
            <div className="flex items-center space-x-2">
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="p-2 border rounded"
                aria-label="Filter by status"
              >
                <option value="">Select Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>

              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search events..."
                className="p-2 border rounded"
                aria-label="Search bulletins"
              />
              <button
                onClick={handleSearch}
                className="ml-2 p-2 bg-blue-500 text-white rounded"
              >
                Search
              </button>
              <button
                onClick={() => navigate("/events/create")}
                className="bg-green-600 text-white p-2 rounded hover:bg-green-700 flex items-center"
                aria-label="Create event"
              >
                <FaPlus className="mr-2" /> Create Event
              </button>
            </div>
          </div>
        }
      />
      <EditEventModal
        event={selectedEvent}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onEventUpdate={handleEventUpdate}
      />
    </div>
  );
};

export default EventTable;
