import {
  faCheckCircle,
  faClock,
  faExclamationTriangle,
  faTimesCircle,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Bar, Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { get } from "../../utils/fetchAPI";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [clubSummary, setClubSummary] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await get("admin/dashboards");
        if (response.data.success) {
          setClubSummary(response.data.data.clubSummery[0]);
        }
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
        localStorage.removeItem("user");
        navigate("/login");
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!clubSummary) {
    return <div>No data available</div>;
  }

  const stats = [
    {
      label: "Total Clubs",
      value: clubSummary.totalClub,
      icon: faUsers,
      color: "bg-blue-500",
    },
    {
      label: "Pending Clubs",
      value: clubSummary.pendingClubs,
      icon: faClock,
      color: "bg-yellow-500",
    },
    {
      label: "Processing Clubs",
      value: clubSummary.processingClubs,
      icon: faExclamationTriangle,
      color: "bg-orange-500",
    },
    {
      label: "Approved Clubs",
      value: clubSummary.approvedClubs,
      icon: faCheckCircle,
      color: "bg-green-500",
    },
    {
      label: "Rejected Clubs",
      value: clubSummary.rejectedClubs,
      icon: faTimesCircle,
      color: "bg-red-500",
    },
  ];

  const options = {
    plugins: {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 15,
          padding: 10,
        },
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const total = tooltipItem.chart.data.datasets[0].data.reduce(
              (acc, curr) => acc + curr,
              0
            );
            const value = tooltipItem.raw;
            const percentage = ((value / total) * 100).toFixed(2);
            return `${tooltipItem.label}: (${percentage}%)`;
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="mx-auto p-4 bg-gray-100">
      <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">
          Club Registration Summary
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {stats.map((stat, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-center p-6 rounded-lg shadow-md text-white ${stat.color} transform hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-out min-h-[120px]`}
            >
              <FontAwesomeIcon icon={stat.icon} size="2x" className="mb-2" />
              <span className="text-sm font-semibold">{stat.label}</span>
              <div className="text-2xl font-bold">{stat.value}</div>
            </div>
          ))}
        </div>
      </section>

      <section className="mb-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold mb-4">
          Club Registration: Approval Status
        </h2>
        <div className="flex justify-center">
          <div style={{ width: "300px", height: "300px" }}>
            <Pie
              data={{
                labels: ["Approved", "Pending", "Processing", "Rejected"],
                datasets: [
                  {
                    data: [
                      clubSummary.approvedClubs * 0.1,
                      clubSummary.pendingClubs * 10.5,
                      clubSummary.processingClubs * 7.5,
                      clubSummary.rejectedClubs * 5.5,
                    ],
                    backgroundColor: [
                      "#4caf50",
                      "#ffeb3b",
                      "#ff9800",
                      "#f44336",
                    ],
                    hoverOffset: 4,
                  },
                ],
              }}
              options={options}
            />
          </div>
          <div className="ml-2 text-sm space-y-2">
            <div className="flex items-center">
              <div className="w-4 h-4 bg-green-500 mr-1"></div> Approved:{" "}
              {clubSummary.approvedClubs}
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-yellow-500 mr-1"></div> Pending:{" "}
              {clubSummary.pendingClubs}
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-orange-500 mr-1"></div> Processing:{" "}
              {clubSummary.processingClubs}
            </div>
            <div className="flex items-center">
              <div className="w-4 h-4 bg-red-500 mr-1"></div> Rejected:{" "}
              {clubSummary.rejectedClubs}
            </div>
          </div>
        </div>
      </section>

      <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">3ZERO Focus Areas</h2>
          <Bar
            data={{
              labels: [
                "Zero net carbon emission",
                "Zero wealth concentration",
                "Zero unemployment",
              ],
              datasets: [
                {
                  data: [33, 4502, 4521],
                  backgroundColor: ["#42a5f5", "#66bb6a", "#ffca28"],
                },
              ],
            }}
            options={{ indexAxis: "y" }}
          />
        </section>

        <section className="flex-1 p-6 bg-white rounded-lg shadow-md">
          <h2 className="text-xl font-semibold mb-2">Referral Sources</h2>
          <Bar
            data={{
              labels: [
                "Others",
                "Facebook",
                "LinkedIn",
                "3ZERO Club website",
                "Friends or colleagues",
              ],
              datasets: [
                {
                  data: [97, 152, 44, 409, 644],
                  backgroundColor: [
                    "#ab47bc",
                    "#29b6f6",
                    "#78909c",
                    "#8d6e63",
                    "#ffa726",
                  ],
                },
              ],
            }}
            options={{ indexAxis: "y" }}
          />
        </section>
      </div>

      <section className="mt-6 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-xl font-semibold mb-2">
          Country Wise Club Registration
        </h2>
        <Bar
          data={{
            labels: [
              "Bangladesh",
              "Kenya",
              "Uganda",
              "China",
              "Pakistan",
              "Canada",
            ],
            datasets: [
              {
                data: [268, 71, 68, 32, 8, 3],
                backgroundColor: [
                  "#42a5f5",
                  "#66bb6a",
                  "#ffca28",
                  "#ef5350",
                  "#29b6f6",
                  "#8d6e63",
                ],
              },
            ],
          }}
        />
      </section>
    </div>
  );
};

export default Dashboard;
